<template>
  <div>
    <h1>Fehler beim Laden der Seite!</h1>
    <h3> Es gab einen Serverfehler beim Laden der Seite. <br>Wenn der Fehler besteht melden Sie sich beim Support: <a href="mailto:support@govforms.ch">support@govforms.ch</a></h3>
  </div>
</template>
<script>

export default {
  components: {},
  data: () => ({
  }),
  computed: {
  }
}

</script>
<style>

</style>
