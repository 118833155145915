export default {
  activity: [
    {
      text: 'Statusänderung',
      searchValue: 'Status'
    },
    {
      text: 'Gesucherstellung',
      searchValue: 'Gesuch erstellt'
    },
    {
      text: 'EGID',
      searchValue: 'EGID'
    },
    {
      text: 'Strasse',
      searchValue: 'Strasse'
    },
    {
      text: 'Hausnummer',
      searchValue: 'Hausnummer'
    },
    {
      text: 'Ort',
      searchValue: 'Ort'
    },
    {
      text: 'PLZ',
      searchValue: 'PLZ'
    },
    {
      text: 'Bemerkung',
      searchValue: 'Bemerkung'
    }
  ]
}
